import React from 'react';
import { Page } from '../components';
import PrivacyPolicy from '../sections/PrivacyPolicy'


const PrivacyPolicyPage = () => (
	<Page>
		<PrivacyPolicy />
	</Page>
);

export default PrivacyPolicyPage;
