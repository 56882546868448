import React from 'react';
import styled, { withTheme, css } from 'styled-components';
import useMedia from 'react-use/lib/useMedia';
import { media } from '../theme';

// Components
import { Space, Background, Card } from '../components';

// Styles
import { UnorderedList, UnorderedListItem, HeadLegal, TextLegal, ALegal, Content, Section, OL, OI } from '../styles';

const PrivacyPolicy = ({ theme }) => {
	const isPhone = useMedia(`(max-width: ${theme.sizes.phone}px)`);
	return (
		<Content>
			<Section id="PrivacyPolicy">
				<Background
					color={theme.secondaryColor}
					padding={{phone: '19px 19px 55px 19px', def: '79px 60px 100px 18px'}}
				>
					<Card
						spacing={spacing}
						margin={{phone: '0',  def: '0 0 0 -40px'}}
					>
						<HeadLegal>Privacy Policy</HeadLegal>
						<Space height="20" />
						<OL bold fontSize="24px">
							<OI>ABOUT US</OI>
							<TextLegal bold>What is AgePass?</TextLegal>
							<TextLegal>
								AgePass is an anonymous online age-verification consumer product built using zero
								knowledge proof and blockchain technologies. Our blockchain is a private blockchain that
								acts as the repository of encrypted credentials that represent age-verified consumers.
							</TextLegal>
							<TextLegal>
								AgePass requests you perform an age-authentication check with trusted third-parties
								(through a choice of options – see below) and once validated, our software allows you,
								using your browser, to create an anonymous ‘root’ access AgePass that is able to
								generate one-time use proof-of-age tokens when you visit websites that require you to
								prove your age. By giving users the ability to generate their own ‘valid’ one-time use
								proof-of-age tokens, our customers can be confident that neither we, nor any of the
								websites that use our technology, nor our partners, are able to track their activity
								using our technology.
							</TextLegal>
							<TextLegal bold>
								<InlineText bold underline>
									Bottom line:
								</InlineText>{' '}
								we do not collect your information (unless you give up your anonymity and contact us
								directly – see below) and we have no way of tracking information about how, where and
								when, you use your AgePass verification credentials.
							</TextLegal>
							<TextLegal>
								Neither the Websites, the App nor our services are intended for anyone under eighteen
								(18) and we do not knowingly collect any data relating to individuals under eighteen
								(18).
							</TextLegal>
							<TextLegal bold>AVSecure LLC</TextLegal>
							<TextLegal>
								AVSecure LLC is responsible for your information (collectively referred to as{' '}
								<InlineText bold>"AVSecure LLC"</InlineText>
								, <InlineText bold>"we"</InlineText>
								, <InlineText bold>"us"</InlineText> or <InlineText bold>"our"</InlineText> in this
								privacy policy.
							</TextLegal>
							<TextLegal>
								If you have any questions about this privacy policy or our privacy practices, please
								contact us at: AVSecure LLC, 1097 Jupiter Park Lane Suite 3, Jupiter, Florida, USA,
								33458 or via our website contact form{' '}
								<ALegal href="https://www.agepass.com/#contact" target="_blank" rel="noopener noreferrer">
									www.agepass.com/#contact
								</ALegal>.
							</TextLegal>
							<TextLegal>
								We keep our privacy policy under regular review and reserve the right to revise, amend,
								or modify this policy, providing any notice required by applicable law.
							</TextLegal>
							<TextLegal bold>Our privacy policy</TextLegal>
							<TextLegal>
								This privacy policy aims to give you information on what information (if any) AVSecure
								LLC collects and processes:
								<UnorderedList>
									<UnorderedListItem>
										online, via the{' '}
										<ALegal href="https://www.agepass.com" target="_blank" rel="noopener noreferrer">
											www.agepass.com
										</ALegal>{' '}
										website (desktop and mobile versions) and{' '}
										<ALegal href="https://www.agepass.net" target="_blank" rel="noopener noreferrer">
											www.agepass.net
										</ALegal>{' '}
										(our online age verification product) (
										<InlineText bold>Websites</InlineText>
										) or AVSecure’s app (
										<InlineText bold>App</InlineText>
										), together the <InlineText bold>Platforms</InlineText>
										; and
									</UnorderedListItem>
									<UnorderedListItem>
										offline, via all channels of communication including post, phone or otherwise
									</UnorderedListItem>
								</UnorderedList>
								Other privacy policies, as notified to you, may apply to you if you use other products
								or services we provide
							</TextLegal>
							<OI>DATA WE COLLECT AND DATA WE DO NOT COLLECT</OI>
							<TextLegal bold>What we do not collect</TextLegal>

							<TextLegal>
								<InlineText bold>
									We do not collect any personal information about you when you use AgePass.
								</InlineText>{' '}
								As explained above, where we use a third party to help verify your age the only
								information we receive from the third party is a “pass/fail” based on whether you are
								eighteen (18) years or older. We do not receive, nor have any right to receive, any
								other information about you from these third parties which you may have provided to them
								during the age verification process. Once we have received confirmation that a user is
								eighteen (18) years or older, the user’s browser generates a unique AgePass age
								credential from Access Key and user-chosen PIN +SALT. We know the AgePass anonymous age
								credentials that are valid, but we do not know the user Access Key or PIN. We also do
								not know, and have no means of knowing, the identity of the person who uses an AgePass
								or their Access Key or PIN.
							</TextLegal>
							<TextLegal>
								<InlineText bold>
									Our Platforms do not collect any personal information about you.
								</InlineText>{' '}
								Specifically:
								<UnorderedList>
									<UnorderedListItem>
										<InlineText bold>IP information</InlineText>: When accessing any website, your
										web browser automatically sends your Internet Protocol (IP) address. While most
										companies log or store this information, we take affirmative steps to not retain
										any user IP information.
									</UnorderedListItem>
									<UnorderedListItem>
										<InlineText bold>User agent information</InlineText>: When accessing any
										website, your web browser also sends “user agent” information detailing the type
										of software initiating the request. We do not retain or use any user agent
										information.
									</UnorderedListItem>
									<UnorderedListItem>
										<InlineText bold>Browser fingerprinting</InlineText>: We do not use any browser
										fingerprinting that can identify a single user. We do utilise client-side
										encrypted ‘group’ browser generated fingerprinting data to help manage security
										and fraud risks on our platform by getting client’s browsers to re-authenticate
										their credentials periodically.
									</UnorderedListItem>
									<UnorderedListItem>
										<InlineText bold>Cookies</InlineText>: Our Platforms do not use cookies or store
										information associated with cookies
									</UnorderedListItem>
									<UnorderedListItem>
										<InlineText bold>Web beacons</InlineText>: Our Platforms do not use web beacons
										and we do not store any information associated with web beacons.
									</UnorderedListItem>
									<UnorderedListItem>
										<InlineText bold>Analytics information</InlineText>: Our Platforms do not use or
										store information about its users for purposes of analyzing their online
										activity.
									</UnorderedListItem>
								</UnorderedList>
							</TextLegal>
							<TextLegal bold>What we do collect</TextLegal>
							<TextLegal bold>
								We only collect the anonymised encrypted age-verification credentials after a successful
								age verification check. This credential identifies the age verification provider but NOT
								the individual who was verified; this cannot personally identify user.
							</TextLegal>
							<TextLegal>
								<InlineText bold>
									If you choose to give up your anonymity by contacting us directly and providing us
									with your details, we collect your personal information to deal with your query.
								</InlineText>{' '}
								We have designed our age verification service to safeguard the privacy and anonymity of
								users of AgePass. However, if you choose to contact us and lose your anonymity, we
								collect the information you actively submit to us (for example via use of our customer
								contact form or via offline communications such as post).
							</TextLegal>
							<TextLegal>
								<InlineText bold>
									We collect and use aggregated (non-identifiable) usage data about the Platforms
								</InlineText>{' '}
								for example, to calculate the percentage of users accessing a specific Platform feature,
								but this is not personally identifiable.
							</TextLegal>
							<OI>OUR THIRD PARTY PARTNERS</OI>
							<TextLegal>
								As explained above, AgePass requests you perform an age-authentication check with
								trusted third-parties. Accordingly, the Platform links to third-party websites and
								applications. These third parties verify your age directly and, if successful, we will
								issue an AgePass. The third party involved depends on the age verification method you
								select through our Platforms.
							</TextLegal>
							<TextLegal bold>
								Where we use a third party to help verify your age the only information we receive from
								the third party is a “pass/fail” based on whether you are eighteen (18) years or older.
								We cannot access and have no right to access any other information about you from these
								third parties.
							</TextLegal>
							<TextLegal bold>Our Third Party Partners by Age Verification Method</TextLegal>
							<UnorderedList>
								<UnorderedListItem>
									<InlineText bold>Age Verification Card</InlineText>: This method does not involve
									any interface with a third party.
								</UnorderedListItem>
								<UnorderedListItem>
									<InlineText bold>UK Paysafecard</InlineText>: We use Paysafecard (
									<ALegal
										href="https://www.paysafecard.com/en-gb/"
										target="_blank"
										rel="noopener noreferrer"
									>
										https://www.paysafecard.com/en-gb/
									</ALegal>{' '}
									) to help us verify your age. Please see Paysafecard’s privacy policy (
									<ALegal
										href="https://www.paysafecard.com/en-gb/data-protection/"
										target="_blank"
										rel="noopener noreferrer"
									>
										https://www.paysafecard.com/en-gb/data-protection/
									</ALegal>
									).
								</UnorderedListItem>
								<UnorderedListItem>
									<InlineText bold>Yoti Digital Identity</InlineText>: We use Yoti (
									<ALegal href="https://www.yoti.com/" target="_blank" rel="noopener noreferrer">
										https://www.yoti.com/
									</ALegal>{' '}
									) to help us verify your age. Please see Yoti’s privacy policy (
									<ALegal
										href="https://www.yoti.com/privacypolicy/"
										target="_blank"
										rel="noopener noreferrer"
									>
										https://www.yoti.com/privacypolicy/
									</ALegal>
									).
								</UnorderedListItem>
								<UnorderedListItem>
									<InlineText bold>UK Credit/Debit Card</InlineText>: We use Aristotle International,
									Inc. (
									<ALegal href="http://aristotle.com/" target="_blank" rel="noopener noreferrer">
										http://aristotle.com/
									</ALegal>{' '}
									) to help us verify your age. Please see Aristotle’s privacy policy (
									<ALegal
										href="https://integrity.aristotle.com/privacy-policy/"
										target="_blank"
										rel="noopener noreferrer"
									>
										https://integrity.aristotle.com/privacy-policy/
									</ALegal>
									). Please note Aristotle is based in the United States and any data transferred to
									it is processed pursuant to the EU-U.S. Privacy Shield.
								</UnorderedListItem>
								<UnorderedListItem>
									<InlineText bold>UK Mobile Phone</InlineText>: We use Mia Pago (
									<ALegal href="https://www.miapago.com/" target="_blank" rel="noopener noreferrer">
										https://www.miapago.com/
									</ALegal>{' '}
									) to help us verify your age. Please see Mia Pago’s privacy policy (
									<ALegal href="https://www.miapago.com/" target="_blank" rel="noopener noreferrer">
										https://www.miapago.com/
									</ALegal>
									).
								</UnorderedListItem>
								<UnorderedListItem>
									<InlineText bold>UK Driving Licence</InlineText>: We use Mia Pago (
									<ALegal href="https://www.miapago.com/" target="_blank" rel="noopener noreferrer">
										https://www.miapago.com/
									</ALegal>{' '}
									) to help us verify your age. Please see Mia Pago’s privacy policy (
									<ALegal href="https://www.miapago.com/" target="_blank" rel="noopener noreferrer">
										https://www.miapago.com/
									</ALegal>
									).
								</UnorderedListItem>
							</UnorderedList>
							<TextLegal>
								We do not control these third-party websites and are not responsible for their privacy
								statements and practices. We strongly encourage you to read the privacy policies and
								statements of the above third parties before selecting your age verification method.
							</TextLegal>
							<OI>USING INFORMATION</OI>
							<TextLegal>
								We process any information you choose to provide us with as necessary to respond to your
								query. Our legal grounds are the performance of our contract with you and our legitimate
								interests, as indicated above. We will also process information where required to do so
								by law or to comply with a legal obligation.
							</TextLegal>
							<OI>SHARING INFORMATION</OI>
							<TextLegal>
								If you provide us with your personal information, we may share it within our group of
								companies, including AV_Card LLC, to the extent necessary to deal with the matter.
							</TextLegal>
							<TextLegal>
								Our servers are hosted on Amazon Web Services in the UK. We also use other service
								providers who provide IT and system administration services. We may use professional
								advisors (e.g. lawyers, accountants, auditors etc.) and we may share information with
								regulators or other governmental or legal authorities where required.
							</TextLegal>
							<TextLegal>
								If we sell, transfer or merge parts of our business or our assets, we share information
								with the purchaser of that business/those assets. Alternatively, we may seek to acquire
								other businesses or merge with them. If a change happens to our business, then the new
								owners may use any information in the same way as set out in this privacy policy.
							</TextLegal>
							<OI>SHARING INFORMATION</OI>

							<TextLegal>
								If you provide us with your personal information, we will only retain it for as long as
								reasonably necessary to respond to your query and satisfy any legal or reporting
								requirements. We may retain your information for a longer period in the event of a
								complaint or if we reasonably believe there is a prospect of litigation in respect to
								our relationship with you.
							</TextLegal>
							<OI>TRANSFERRING INFORMATION</OI>

							<TextLegal>
								If you provide us with your personal information, we may share it with our personnel in
								the United States and within our group of companies, including AV_Card LLC, to the
								extent necessary to deal with the matter. We are certified to the Privacy Shield, which
								gives personal information the same protection it has in Europe. Please see below.
							</TextLegal>

							<TextLegal>
								To the extent our service providers are based outside the EEA and receive personal
								information, we ensure the transfer is either (a) to a country deemed to provide an
								adequate level of protection by the European Commission, (b) pursuant to specific
								contracts approved by the European Commission, or (c) for transfers to the US, pursuant
								to that third party’s certification to the Privacy Shield.
							</TextLegal>
							<TextLegal bold>AVSecure Privacy Shield</TextLegal>
							<TextLegal>
								This section provides additional information on how AVSecure LLC and its subsidiaries
								and affiliates in the United States (
								<InlineText bold>US</InlineText>
								) collect, use, and disclose certain personally identifiable information that we receive
								in the US from the European Economic Area (
								<InlineText bold>EEA</InlineText>
								) and the United Kingdom (
								<InlineText bold>UK</InlineText>
								).
							</TextLegal>
							<TextLegal>
								To provide adequate protection for certain personal information about consumers,
								corporate customers, suppliers, business partners received in the US from the EEA or the
								UK, AVSecure LLC has elected to self-certify to the EU-US Privacy Shield Framework
								administered by the US Department of Commerce (
								<InlineText bold>Privacy Shield</InlineText>
								). AVSecure LLC adheres to the Privacy Shield Principles of Notice, Choice,
								Accountability for Onward Transfer, Security, Data Integrity and Purpose Limitation,
								Access, and Recourse, Enforcement, and Liability.
							</TextLegal>
							<TextLegal>
								AVSecure LLC is subject to the investigatory and enforcement authority of the US Federal
								Trade Commission. For more information about the Privacy Shield, see the US Department
								of Commerce's Privacy Shield website located at:{' '}
								<ALegal href="https://www.privacyshield.gov" target="_blank" rel="noopener noreferrer">
									https://www.privacyshield.gov
								</ALegal>. To review our representation on the Privacy Shield list, see the US
								Department of Commerce's Privacy Shield self-certification list located at: {' '}
								<ALegal
									href="https://www.privacyshield.gov/list"
									target="_blank"
									rel="noopener noreferrer"
								>
									https://www.privacyshield.gov/list
								</ALegal>.
							</TextLegal>
							<TextLegal>
								Please see above for the information we may collect and use. Where required by the
								Privacy Shield, we enter into written agreements with any relevant third-party agents
								and service providers requiring them to provide the same level of protection the Privacy
								Shield requires and limiting their use of the data to the specified services provided on
								our behalf. We take reasonable and appropriate steps to ensure that third-party agents
								and service providers process any personal information in accordance with our Privacy
								Shield obligations and to stop and remediate any unauthorized processing. Under certain
								circumstances, we may remain liable for the acts of our third-party agents or service
								providers who perform services on our behalf for their handling of any personal
								information that we transfer to them.
							</TextLegal>
							<TextLegal>
								AVSecure LLC maintains reasonable and appropriate security measures to protect
								information from loss, misuse, unauthorized access, disclosure, alteration, or
								destruction in accordance with the Privacy Shield.
							</TextLegal>
							<TextLegal>
								For any unresolved complaints, we have agreed to cooperate with the{' '}
								<ALegal
									href="http://go.adr.org/privacyshield.html"
									target="_blank"
									rel="noopener noreferrer"
								>
									ICDR-AAA EU-U.S. Privacy Shield Independent Recourse Mechanism
								</ALegal>. If you are unsatisfied with the resolution of your complaint, you may contact
								the ICDR-AAA.
							</TextLegal>
							<TextLegal>
								You may have the option to select binding arbitration for the resolution of your
								complaint under certain circumstances, provided you have taken the following steps: (1)
								raised your complaint directly with us and provided us the opportunity to resolve the
								issue; (2) made use of the independent dispute resolution mechanism identified above;
								and (3) raised the issue through the relevant data protection authority and allowed the
								US Department of Commerce an opportunity to resolve the complaint at no cost to you. For
								more information on binding arbitration, see{' '}
								<ALegal
									href="https://www.privacyshield.gov/article?id=ANNEX-I-introduction"
									target="_blank"
									rel="noopener noreferrer"
								>
									US Department of Commerce's Privacy Shield Framework: Annex I (Binding Arbitration)
								</ALegal>.
							</TextLegal>
							<OI>YOUR LEGAL RIGHTS</OI>
							<TextLegal bold>
								You are always entitled to exercise your legal rights. However, please note that due to
								the nature of AgePass, the anonymity it provides, and the fact that we do not collect
								your personal information, the information you disclose to us if you contact us directly
								will almost always be only information which we have about you.
							</TextLegal>
							<TextLegal>
								Under certain circumstances, you have rights under data protection laws in relation to
								any personal information we have about you. We try to respond to all legitimate requests
								within one month, although we will notify you if it will take longer.
								<UnorderedList>
									<UnorderedListItem>Request access to your personal information.</UnorderedListItem>
									<UnorderedListItem>Request correction of your personal information</UnorderedListItem>
									<UnorderedListItem>Request erasure of your personal information.</UnorderedListItem>
									<UnorderedListItem>Object to processing of your personal information</UnorderedListItem>
									<UnorderedListItem>Request restriction of processing your personal information</UnorderedListItem>
									<UnorderedListItem>Request transfer of your personal information.</UnorderedListItem>
									<UnorderedListItem>Right to withdraw consent (where we rely on your consent as our basis for processing).</UnorderedListItem>
								</UnorderedList>
								You have the right to make a complaint at any time to your supervisory authority for
								data protection issues.
							</TextLegal>
						</OL>
						<Space height="120" />
					</Card>
				</Background>
			</Section>
		</Content>
	);
};

export default withTheme(PrivacyPolicy);

const InlineText = styled(TextLegal)`
	display: inline;
	text-decoration: ${({ underline }) => (underline ? 'underline' : 'inherit')};
    font-style: ${({ bold }) => (bold ? 'bold' : 'inherit')};
    color: ${({ theme, color = theme.legalColor }) => color};
    &.red {
        color: #f00;
    }
`;

const spacing = css`
    padding: 52px 50px 40px 50px;
    
		${media.laptop`
        padding: 36px 42px 20px 42px;
    `}

    ${media.phone`
        padding: 36px 42px 20px 42px;
    `}
`;
